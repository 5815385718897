import gql from 'graphql-tag';

export default gql`
  fragment CommonAddress on Address {
    line1
    line2
    city
    state
    country
    zip
  }
`;
