import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

const COLORS = ['#ff4d4f', '#ffa940', '#ffec3d', '#a0d911', '#52c41a'];

const PasswordStrengthIndicator = props => {
  const { level, ...rest } = props;
  const color = COLORS[level];

  return (
    <Progress
      {...rest}
      percent={((level + 1) / 5) * 100}
      strokeColor={color}
      status={level < 3 ? 'exception' : 'success'}
    />
  );
};

PasswordStrengthIndicator.propTypes = {
  level: PropTypes.number.isRequired,
};

export default PasswordStrengthIndicator;
