import gql from 'graphql-tag';
import CommonImage from './image';
import CommonAddress from './address';

export default gql`
  fragment CommonAccount on Account {
    _id
    firstName
    lastName
    name
    email
    slackId
    phone
    image {
      ...CommonImage
    }
    address {
      ...CommonAddress
    }
    facility {
      _id
      name
      facSettings {
        showSurvey
        pcp
        psychConsultLocs
        telemedWorkflow
        pccOrgUuid
        pccFacId
      }
    }
    jobTitle
    role
    timezone
    pccAccounts {
      info {
        username
      }
    }
    roles {
      role
      facility {
        _id
        name
        isPsychFacility
      }
      permissions
    }
    permissions
    isEmergencyAccessEnabled
    isProvider
    providerDetails {
      npiNumber
      deaNumber
      template {
        _id
        name
      }
      medicalLicenses {
        state
        licenseNumber
      }
    }
    color
  }
  ${CommonImage}
  ${CommonAddress}
`;
